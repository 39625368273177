<app-sidebar 
[sidebarState]="sidebarState" 
(toggle)="toggleSidebar(viewportType)"
></app-sidebar>

<div class="app-main-content">
  <div class="content-header layout-item" [ngClass]="'sidebar-state-' + sidebarState">
    <div class="header-controls">
      <app-hamburger-button (click)="toggleSidebar(viewportType)"></app-hamburger-button>
      <shared-main-logo></shared-main-logo>
    </div>
    <div class="layout-content">
      <div class="layout-title">
        <app-layout-title *ngIf="(viewportType !== 'mobile') && (sidebarState !== 'closed')"></app-layout-title>
      </div>
      <div class="layout-actions">
        <shared-language-selector></shared-language-selector>
        <app-horizontal-user-cabinet></app-horizontal-user-cabinet>
      </div>
    </div>
  </div>

  <div class="router-outlet">
    <div class="wrapper-2" style="margin: 10px auto">
      <div class="layout-item layout-title" *ngIf="(viewportType === 'mobile') || (sidebarState === 'closed')">
        <app-layout-title></app-layout-title>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
