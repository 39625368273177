import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-hamburger-button',
  templateUrl: './hamburger-button.component.html',
  styleUrls: ['./hamburger-button.component.scss']
})
export class HamburgerButtonComponent {
  @Input() open: boolean = false

  constructor () { }
}
