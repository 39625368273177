import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationTitleService, shoutlyTitle } from 'shared-lib'
import { Location } from '@angular/common'
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-layout-title',
  templateUrl: './layout-title.component.html',
  styleUrls: ['./layout-title.component.scss']
})
export class LayoutTitleComponent implements OnInit, OnDestroy {
  constructor (
    private navigationTitleService: NavigationTitleService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) { }

  private destroy$: Subject<void> = new Subject()
  public titleObject: shoutlyTitle = null
  public isHandset = false

  ngOnInit (): void {
    this.navigationTitleService.title$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (title: shoutlyTitle) => {
          this.titleObject = title
          this.cdr.detectChanges()
        }
      })
  }

  ngOnDestroy () {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public goBack () {
    return this.location.back()
  }
}
