<div class="sidebar-container custom-sidenav" [ngClass]="sidebarState" [style.--vh.px]="vh">
    <!-- The Fixed Header Section -->
    <div class="sidebar-header">
      <app-hamburger-button *ngIf="sidebarState !== 'opened'" (click)="onToggleClick()"></app-hamburger-button>
      <shared-main-logo *ngIf="sidebarState !== 'collapsed'" [opened]="sidebarState === 'opened'"></shared-main-logo>
      <div class="collapse-toggle-wrapper" *ngIf="sidebarState === 'opened'" (click)="onToggleClick()">
        <button mat-icon-button>
          <mat-icon fontSet="material-icons-outlined">arrow_back_ios</mat-icon>
        </button>
      </div>
    </div>
    
    <!-- The Scrollable Content Section -->
    <div class="main-sidebar-section">
      <div class="sidebar-menu-items-container">
        <app-sidebar-menu-items [collapsed]="sidebarState === 'collapsed'"></app-sidebar-menu-items>
      </div>
    </div>
    
    <!-- The Bottom Org-Switcher Section -->
    <div class="org-switcher">
      <app-org-switcher [sidebarState]="sidebarState"></app-org-switcher>
    </div>
  </div>
  