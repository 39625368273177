import { BreakpointObserver } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Observable, filter, map, shareReplay } from 'rxjs'
import { StoreService, User } from 'shared-lib'

@Component({
  selector: 'app-horizontal-user-cabinet',
  templateUrl: './horizontal-user-cabinet.component.html',
  styleUrls: ['./horizontal-user-cabinet.component.scss']
})
export class HorizontalUserCabinetComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 850px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    )

  user: User
  expandedElement: any
  isActive: boolean = false

  constructor (
    private storeService: StoreService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) { }

  ngOnInit (): void {
    this.storeService.user$
      .subscribe({
        next: (user) => {
          this.user = user
        }
      })

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.isActive = this.checkIfActiveRoute()
      })

    // Initial check
    this.isActive = this.checkIfActiveRoute()
  }

  public checkIfActiveRoute (): boolean {
    const currentUrl = this.router.url
    return currentUrl.startsWith('/notifications') || currentUrl.startsWith('/settings')
  }

  public goToHelpCenter (event: Event): void {
    event.stopPropagation()
    const url = 'https://www.shoutly.com/q-a-int'
    window.open(url, '_blank')
  }
}
