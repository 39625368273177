import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { SidebarState } from 'shared-lib'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() sidebarState: SidebarState = SidebarState.Opened
  @Input() isHandset: boolean = false
  @Output() toggle = new EventEmitter<void>()

  public vh: number = 100 // Initialize to 100vh equivalent

  ngOnInit () {
    this.adjustSidebarHeight()
  }

  ngOnDestroy () {
    window.removeEventListener('resize', this.adjustSidebarHeight.bind(this))
  }

  @HostListener('window:resize', ['$event'])
  onResize (event) {
    this.adjustSidebarHeight()
  }

  private adjustSidebarHeight () {
    this.vh = window.innerHeight * 0.01
  }

  public onToggleClick () {
    this.toggle.emit()
  }
}
