import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { StoreService, SettingsGenericData } from 'shared-lib'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

interface MenuItem {
  title: string
  icon: string
  link: string
  tooltip: string
  testid: string
  condition?: boolean
}

@Component({
  selector: 'app-sidebar-menu-items',
  templateUrl: './sidebar-menu-items.component.html',
  styleUrls: ['./sidebar-menu-items.component.scss']
})
export class SidebarMenuItemsComponent implements OnInit, OnDestroy {
  @Input() collapsed: boolean = false;

  constructor(private storeService: StoreService) { }

  public combinedData: SettingsGenericData
  public menuItems: { category: string; items: MenuItem[] }[]
  public isLoading = true;
  private destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.storeService.sessionData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((combinedData) => {
        this.isLoading = false
        this.combinedData = combinedData
        this.setItems()
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private setItems() {
    const { org, org_billing } = this.combinedData
    const orgType = org?.type
    const hasBalance = org?.has_balance
    const orgForm = org?.form

    this.menuItems = [
      {
        category: 'Main',
        items: [
          { title: 'dashboard', icon: 'sidebar-dashboard', link: '/dashboard', tooltip: 'dashboard', testid: 'sidebar-menu-item-dashboard' },
          { title: 'analytics', icon: 'sidebar-analytics', link: '/charts', tooltip: 'analytics', testid: 'sidebar-menu-item-charts' }
        ]
      },
      {
        category: 'Collaborations',
        items: [
          { title: 'collaborations', icon: 'sidebar-collaborations', link: '/collaborations', tooltip: 'collaborations', testid: 'sidebar-menu-item-collaborations' },
          { title: 'spreadsheets upload', icon: 'sidebar-spreadsheets-upload', link: '/collaborations/spreadsheet', tooltip: 'spreadsheets upload', testid: 'sidebar-menu-item-upload-collaborations', condition: org_billing?.csv_import },
          { title: 'reporting', icon: 'sidebar-reporting', link: '/collab-report', tooltip: 'reporting', testid: 'sidebar-menu-item-settings', condition: orgType === 'employer' },
          { title: 'timesheets', icon: 'sidebar-timesheets', link: '/collab-report/time-sheets', tooltip: 'timesheets', testid: 'sidebar-menu-item-time-sheets', condition: orgType === 'gigger' }
        ]
      },
      {
        category: 'Billing',
        items: [
          { title: 'transactions', icon: 'sidebar-transactions', link: '/billing/transactions', tooltip: 'transactions', testid: 'sidebar-menu-item-billing-transactions' },
          { title: 'bills', icon: 'sidebar-bills', link: '/billing/bills', tooltip: 'bills', testid: 'sidebar-menu-item-billing-bills', condition: orgType === 'employer' },
          { title: 'balance', icon: 'sidebar-balance', link: '/billing/balance', tooltip: 'balance', testid: 'sidebar-menu-item-balance', condition: hasBalance },
          { title: _('service sheets'), icon: 'sidebar-self-invoices', link: '/billing/self-invoices', tooltip: 'service sheets', testid: 'sidebar-menu-item-self-invoices', condition: orgType === 'gigger' && orgForm !== 'Private' },
          { title: _('salary sheets'), icon: 'sidebar-self-invoices', link: '/billing/self-invoices', tooltip: 'salary sheets', testid: 'sidebar-menu-item-self-invoices', condition: orgType === 'gigger' && orgForm === 'Private' }
        ]
      },
      {
        category: 'Partner',
        items: [
          { title: _('giggers manager'), icon: 'sidebar-counterpart', link: '/partner', tooltip: 'giggers manager', testid: 'sidebar-menu-item-counterpart', condition: orgType === 'employer' },
          { title: _('employers'), icon: 'sidebar-counterpart', link: '/partner', tooltip: 'employers', testid: 'sidebar-menu-item-counterpart', condition: orgType === 'gigger' }
        ]
      },
      {
        category: 'Team',
        items: [
          { title: 'team', icon: 'sidebar-team', link: '/teams/departments', tooltip: 'team', testid: 'sidebar-menu-item-departments' }
        ]
      }
    ]
  }
}