import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

export interface ConnectProvider {
  name: string
  token: string
  scope: string
  code: string
}

@Component({
  selector: 'app-connect-provider',
  template: ''
})
export class ConnectProviderComponent implements OnInit {
  constructor (
    private route: ActivatedRoute
  ) { }

  provider = {
    name: null,
    token: null,
    scope: null,
    code: null
  }

  public isLoading = false

  ngOnInit (): void {
    this.getProvider()

    const targetOrigin = '*'

    if (this.provider.name === 'paypal') {
      this.getPaypalCode()
        .then(
          (provider: ConnectProvider) => {
            window.opener.postMessage({ type: 'oauth_success', token: provider.token }, targetOrigin)
            this.close()
          }
        )
    } else if (this.provider.name === 'fortnox') {
      this.getFortnoxCode()
        .then(
          (code) => {
            window.opener.postMessage({ type: 'oauth_success', code }, targetOrigin)
            this.close()
          }
        )
    }
  }

  private getProvider () {
    // get the provider from the route
    const provider = this.route.snapshot.paramMap.get('provider')
    this.provider.name = provider.toLowerCase()
    return this.provider.name
  }

  private getPaypalCode (): Promise<ConnectProvider> {
    // get the code from the route
    const code = this.route.snapshot.queryParamMap.get('code')
    const scope = this.route.snapshot.queryParamMap.get('scope')
    this.provider.token = code
    this.provider.scope = decodeURI(scope)
    return new Promise((resolve, reject) => {
      if (code) return resolve(this.provider)
      return reject(new Error('No code found'))
    })
  }

  private getFortnoxCode (): Promise<string> {
    const code = this.route.snapshot.queryParamMap.get('code')

    return new Promise((resolve, reject) => {
      if (code) return resolve(code)
      return reject(new Error('No code found'))
    })
  }

  public close () {
    window.close()
  }
}
